import { matchPath, useLocation, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Toolbar, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { THEMES } from 'src/constants';
import { useSelector } from 'react-redux';
import { useGetReferralsCountQuery } from 'src/store/serverState';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: theme.zIndex.drawer - 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.secondary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 0,
    justifyContent: 'flex-start',
    overflowX: 'auto',
  },
  toolbarContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  list: {
    display: 'flex',
    width: '100%',
  },
}));

function RenderNavItems({ items, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.account);
  return (
    <List
      disablePadding
      className={classes.list}
    >
      {items
        .filter(
          (item) =>
            !item.permission || user.permissions?.includes(item.permission),
        )
        .reduce(
          (acc, item, index) =>
            // eslint-disable-next-line no-use-before-define
            reduceChildRoutes({
              acc,
              item,
              index,
              ...rest,
            }),
          [],
        )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0, index }) {
  const key = item.title + depth + index;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        line={item.line}
        disabledProp={item.disabled}
      >
        {RenderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
        disabledProp={item.disabled}
      />,
    );
  }

  return acc;
}
function ThirdTopBar({ className, onMobileNavOpen, rtl = false, ...rest }) {
  const classes = useStyles();
  const location = useLocation();
  const { caseItem } = useSelector((state) => state.caseItem);
  const referralCount = useGetReferralsCountQuery(undefined, {
    skip: !location.pathname.includes('/dashboards'),
  });

  let navItems = [];
  if (useMatch('case/:caseId/referral/*')) {
    navItems = [
      {
        title: 'Summary',
        href: `referral/summary`,
        permission: 'read:referralSummary',
        disabled: !caseItem.id,
      },
      {
        title: 'Worksheet',
        href: `referral/worksheet`,
        permission: 'read:referralWorksheet',
      },
      {
        title: 'Preliminary Donor Screening',
        href: `referral/screening`,
        permission: 'read:referralDonorScreening',
        disabled: !caseItem.id,
      },
      {
        title: 'Preliminary Hemodilution',
        href: `referral/preliminaryHemodilution`,
        permission: 'read:referralPreliminaryHemodilution',
        disabled: !caseItem.id,
      },
      {
        title: 'Contacts',
        href: `referral/contacts`,
        permission: 'read:referralContact',
        disabled: !caseItem.id,
      },
      {
        title: 'Donation Discussion',
        href: `referral/discussion`,
        permission: 'read:referralDonationDiscussion',
        disabled: !caseItem.id,
      },
      {
        title: 'Authorizations',
        href: `referral/authorizations`,
        permission: 'read:authForm',
        disabled: !caseItem.id,
      },
      {
        title: 'Authorized Usage',
        href: `referral/usage`,
        permission: 'read:authForm',
        disabled: !caseItem.id,
      },
    ];
  }

  if (useMatch('case/:caseId/recovery/*')) {
    navItems = [
      {
        title: 'Recovery Supplies',
        href: `recovery/supplies`,
        permission: 'read:recoveryTissue',
        disabled: false,
      },
      {
        title: 'Donor Information',
        href: `recovery/donorInformation`,
        permission: 'read:recoveryDonorInformation',
        disabled: false,
      },
      {
        title: 'Recovery Site',
        href: `recovery/recoverySite`,
        permission: 'read:recoverySite',
        disabled: false,
      },
      {
        title: 'Physical Assessment',
        href: `recovery/physicalAssessment`,
        permission: 'read:recoveryPhysicalAssessment',
        disabled: false,
      },
      {
        title: 'Eye Assessment',
        href: `recovery/eyeAssessment`,
        permission: 'read:recoveryEyeAssessment',
        disabled: false,
      },
      {
        title: 'Tissue Recovery',
        href: `recovery/tissue`,
        permission: 'read:recoveryTissue',
        disabled: false,
      },
      {
        title: 'Serologies',
        href: `recovery/serologies`,
        permission: 'read:recoverySerology',
        disabled: false,
      },
    ];
  }

  if (useMatch('case/:caseId/medicalReview/*')) {
    navItems = [
      {
        title: 'Summary',
        href: `medicalReview/summary`,
        permission: 'read:medicalReviewSummary',
        disabled: false,
      },
      {
        title: 'Pathology',
        href: `medicalReview/pathology`,
        permission: 'read:medicalReviewPathology',
        disabled: false,
      },
      {
        title: 'Lab Values',
        href: `medicalReview/labValues`,
        permission: 'read:medicalReviewLabValues',
        disabled: false,
      },
      {
        title: 'Culture Results',
        href: `medicalReview/cultureResults`,
        permission: 'read:medicalReviewCultureResult',
        disabled: false,
      },
      {
        title: 'Hemodilution',
        href: `medicalReview/hemodilution`,
        permission: 'read:medicalReviewHemodilution',
        disabled: !caseItem.id,
      },
      {
        title: 'Serologies',
        href: `medicalReview/serologies`,
        permission: 'read:medicalReviewSerology',
        disabled: !caseItem?.id,
      },
    ];
  }

  if (useMatch('case/:caseId/transmit/*')) {
    navItems = [
      {
        title: 'Transmit Log',
        href: `transmit/log`,
        permission: 'read:transmitLog',
        disabled: !caseItem.id,
      },
      {
        title: 'Transmit Data',
        href: `transmit/data`,
        permission: 'read:transmit',
        disabled: !caseItem.id,
      },
    ];
  }

  if (useMatch('dashboards/*')) {
    navItems = [
      {
        title: `Active Cases(${referralCount.data?.casesCount || 0})`,
        href: `dashboards/activeCases`,
        permission: 'read:dashboardActiveCases',
        disabled: false,
      },
      {
        title: `Active Referral(${referralCount.data?.referralCount || 0})`,
        href: `dashboards/activeReferral`,
        disabled: false,
      },
    ];
  }

  if (useMatch('case/:caseId/approvals/*')) {
    navItems = [
      {
        title: 'Approval',
        href: `approvals/approval`,
        permission: 'read:approval',
        disabled: false,
      },
      {
        title: 'Disposition',
        href: `approvals/disposition`,
        permission: 'read:disposition',
        disabled: false,
      },
      {
        title: 'Case Lock',
        href: `approvals/lock`,
        permission: 'read:caseLock',
        disabled: false,
      },
    ];
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Box
          className={classes.toolbarContainer}
          style={rtl ? { flexDirection: 'row-reverse' } : null}
        >
          <div>
            <List
              className={classes.list}
              disablePadding
            >
              {RenderNavItems({
                classes,
                items: navItems,
                pathname: location.pathname,
              })}
            </List>
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

ThirdTopBar.propTypes = {
  rtl: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.any,
  onMobileNavOpen: PropTypes.func,
};

RenderNavItems.propTypes = {
  items: PropTypes.any,
};

export default ThirdTopBar;
