import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import axios from 'src/utils/axios';
import * as Sentry from '@sentry/browser';

const titleLookup = [
  { title: 'Summary', href: 'case/:caseId/referral/summary' },
  { title: 'Worksheet', href: 'case/:caseId/referral/worksheet' },
  {
    title: 'Preliminary Donor Screening',
    href: 'case/:caseId/referral/screening',
  },
  { title: 'Med Social', href: 'case/:caseId/medSocial' },
  { title: 'Attachments', href: 'case/:caseId/Attachments' },
  {
    title: 'Preliminary Hemodilution',
    href: 'case/:caseId/referral/preliminaryHemodilution',
  },
  { title: 'Contacts', href: 'case/:caseId/referral/contacts' },
  { title: 'Donation Discussion', href: 'case/:caseId/referral/discussion' },
  { title: 'Authorized Usages', href: 'case/:caseId/referral/usage' },
  { title: 'Authorizations', href: 'case/:caseId/referral/Authorizations' },
  {
    title: 'Donor Information',
    href: 'case/:caseId/recovery/donorInformation',
  },
  { title: 'Recovery Site', href: 'case/:caseId/recovery/recoverySite' },
  {
    title: 'Physical Assessment',
    href: 'case/:caseId/recovery/physicalAssessment',
  },
  { title: 'Eye Assessment', href: 'case/:caseId/recovery/eyeAssessment' },
  { title: 'Tissue Recovery', href: 'case/:caseId/recovery/tissue' },
  {
    title: 'Shipping Details',
    href: 'case/:caseId/recovery/shippingDetails',
  },
  { title: 'Serologies', href: 'case/:caseId/recovery/serologies' },
  { title: 'Pathology', href: 'case/:caseId/medicalReview/pathology' },
  { title: 'CBC/Temp/Meds', href: 'case/:caseId/medicalReview/labValues' },
  {
    title: 'Culture Results',
    href: 'case/:caseId/medicalReview/cultureResults',
  },
  { title: 'Hemodilution', href: 'case/:caseId/medicalReview/hemodilution' },
  { title: 'Transmit Log', href: 'case/:caseId/transmit/log' },
  { title: 'Transmit Data', href: 'case/:caseId/transmit/data' },
  { title: 'Approval', href: 'case/:caseId/approvals/approval' },
  { title: 'Disposition', href: 'case/:caseId/approvals/disposition' },
  { title: 'Case Lock', href: 'case/:caseId/approvals/lock' },
];

function RefreshRecentCase({ caseId }) {
  const location = useLocation();

  useEffect(() => {
    try {
      const match = titleLookup.find((e) => {
        return matchPath({ path: e.href }, location.pathname);
      });
      if (match?.title && caseId) {
        axios.post(`/public/recentCases/${caseId}`, {
          title: match.title,
          href: location.pathname,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [location.pathname, caseId]);
  return null;
}

export default RefreshRecentCase;
