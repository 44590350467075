import { lazy } from 'react';
import {
  TRANSMIT_PAGES as PAGES,
  REFERRAL_PACKETS,
  RECOVERY_PACKETS,
  MEDICAL_PACKETS,
} from 'types/constants';

export const OTHER_USER = {
  id: -1,
  fullName: 'Other',
  suspended: false,
  email: '',
  phone: '',
  noAccess: false,
  entity: 0,
  firstName: '',
  lastName: '',
  timezone: null,
  homeFacilityId: 1,
  lastSeen: '',
  allowSso: false,
  forcePasswordReset: false,
};

export const REQUIRED_FIELD = 'This field is required';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const DRAI_TYPES = [
  { label: 'Uniform DRAI (Donor > 12 yrs old)', value: 'uniformDraiGt12' },
  { label: 'Uniform DRAI (Donor ≤ 12 yrs old)', value: 'uniformDraiLe12' },
  { label: 'Uniform DRAI (Birth Mother)', value: 'uniformDraiBirthMother' },
  {
    label: 'Eye-Only Uniform DRAI (Donor ≤ 12 yrs old)',
    value: 'eyeOnlyUniformDraiLe12',
  },
  {
    label: 'Eye-Only Uniform DRAI (Donor > 12 yrs old)',
    value: 'eyeOnlyUniformDraiGt12',
  },
  {
    label: 'Eye-Only Uniform DRAI (Birth Mother)',
    value: 'eyeOnlyBirthMother',
  },
  { label: 'Addendum for UDRAI (Infectious Diseases)', value: 'addendum' },
];

export const AUTH_FORMS_NO_LOGOS = [
  'TF-750-04',
  'TF-750-08',
  'TF-750-09',
  'TF-750-15',
];

export const SAN_ANTONIO_AUTH_TYPES = [
  {
    label: 'San Antonio Eye-Only Authorization Form',
    value: 'sanAntonioEyeOnly',
    documentNo: 'TF-750-03',
    revision: 'NEW',
    revisionDate: '08/05/2019',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
    extras: {
      tissueRecoveryOrganization: 'San Antonio Eye Bank',
    },
  },
  {
    label: 'San Antonio Tissue Authorization Form',
    value: 'sanAntonioTissue',
    documentNo: 'TF-750-04',
    revision: '3',
    revisionDate: '09/07/2021',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
    extras: {
      tissueRecoveryOrganization: 'San Antonio Eye Bank',
    },
  },
];

export const AUTH_TYPES = [
  {
    label: 'LEBT - Registered Donor Disclosure',
    value: 'registeredDonorDisclosure',
    documentNo: 'TF-750-08',
    revision: 'NEW',
    revisionDate: '10/27/2022',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
    showLogo: false,
  },
  {
    label: 'LEBT - Authorization Form',
    value: 'floridaAuthForm',
    documentNo: 'TF-750-09',
    revision: 'NEW',
    revisionDate: '10/27/2022',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
  {
    label: 'Florida Authorization Form',
    value: 'floridaAuthForm',
    documentNo: 'TF-750-05',
    revision: 'NEW',
    revisionDate: '08/05/2019',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
  {
    label: 'California Authorization Form',
    value: 'californiaAuthForm',
    documentNo: 'TF-750-17',
    revision: 'NEW',
    revisionDate: '10/17/2023',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
  {
    label: 'California Research Authorization Form',
    value: 'californiaResearchAuthForm',
    documentNo: 'TF-750-18',
    revision: 'NEW',
    revisionDate: '10/17/2023',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
  {
    label: 'California Registered Donor Disclosure',
    value: 'californiaRegisteredDonorDisclosure',
    documentNo: 'TF-750-16',
    revision: 'NEW',
    revisionDate: '10/17/2023',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
  {
    label: 'Research Authorization Form',
    value: 'researchAuthForm',
    documentNo: 'TF-750-06',
    revision: 'NEW',
    revisionDate: '08/05/2019',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
  {
    label: 'Registered Tissue Donor Disclosure',
    value: 'registeredTissueDonorDisclosure',
    documentNo: 'TF-750-15',
    revision: '1',
    revisionDate: '09/07/2021',
    ref: 'T-750',
    pageNumber: '1 of 2',
    newFormVersion: 1,
  },
  {
    label: 'Registered Donor Disclosure',
    value: 'registeredDonorDisclosure',
    documentNo: 'TF-750-02',
    revision: 'NEW',
    revisionDate: '08/05/2019',
    ref: 'T-750',
    pageNumber: '1 of 1',
    newFormVersion: 1,
  },
];

export const REPORT_OPTIONS = [
  { label: 'Shipping Report', value: 'shippingReport' },
  {
    label: 'Partner Quar. Tissue Status Summary',
    value: 'partnerQuarTissueStatusSum',
  },
  {
    label: 'Recovery Tech Feedback/Outcomes',
    value: 'recoveryTechFeedbackOutcomes',
  },
  {
    label: 'Outcomes and Dispositions',
    value: 'outcomesDispositions',
  },
  {
    label: 'Serology Logs',
    value: 'serologyLogs',
  },
  {
    label: 'Autopsy Report',
    value: 'autopsyReport',
  },
];

export const TRANSMIT_DATA_TYPE = {
  PACKET: 'PACKET',
  ATTACHMENT: 'ATTACHMENT',
  PAGE: 'PAGE',
  CASE_NOTE: 'CASE_NOTE',
};

export const LOGIN_ERROR_CODE = {
  1: 'Your account is not part of the organization.',
  2: 'Your account has been found but is not marked as a CorneaConnect account, please contact your manager to finish setting up your account.',
  3: 'Your account has been suspended.',
  4: "Your account doesn't have a home facility. Please contact your manager to finish setting up your account.",
  5: 'Something went wrong. Please try again later.',
  6: 'Your account is found but is not marked as SSO, please use regular sign in.',
  7: 'Session has expired.',
  8: 'Logged out on other tab.',
  9: 'Computer has gone to sleep.',
  10: 'Connection has been lost.',
};

export const TRANSMIT_PAGES = [
  {
    label: 'Referral - Summary',
    formId: 'caseSummary',
    pageId: 'referralSummary',
    queryId: PAGES.REFERRAL_SUMMARY,
    component: lazy(() => import('src/views/case/CaseSummaryView')),
  },
  {
    label: 'Referral - Worksheet',
    formId: 'caseWorksheet',
    pageId: 'referralWorksheet',
    queryId: PAGES.REFERRAL_WORKSHEET,
    component: lazy(() => import('src/views/case/CaseWorksheetView')),
  },
  {
    label: 'Referral - Preliminary Donor Screening',
    formId: 'donorScreening',
    pageId: 'referralDonorScreening',
    queryId: PAGES.REFERRAL_DONOR_SCREENING,
    component: lazy(() => import('src/views/case/DonorScreeningView')),
  },
  {
    label: 'Referral - Preliminary Hemodilution',
    formId: 'casePreliminaryHemodilution',
    pageId: 'referralPreliminaryHemodilution',
    queryId: PAGES.REFERRAL_PRELIMINARY_HEMODILUTION,
    component: lazy(() => import('src/views/case/PreliminaryHemodilution')),
  },
  {
    label: 'Referral - Contacts',
    formId: 'caseContacts',
    pageId: 'referralContact',
    queryId: PAGES.REFERRAL_CONTACTS,
    component: lazy(() => import('src/views/case/CaseContacts')),
  },
  {
    label: 'Referral - Donation Discussion',
    formId: 'donationDiscussion',
    pageId: 'referralDonationDiscussion',
    queryId: PAGES.REFERRAL_DONATION_DISCUSSION,
    component: lazy(
      () =>
        import('src/views/case/DonationDiscussionView/DonationDiscussionView'),
    ),
  },
  {
    label: 'Referral - Authorized Usage',
    formId: 'authorizedUsage',
    pageId: 'referralDonationDiscussion',
    queryId: PAGES.REFERRAL_AUTH_USAGE,
    component: lazy(
      () => import('src/views/case/AuthorizedUsageView/AuthorizedUsageView'),
    ),
  },
  {
    label: 'Recovery - Supplies',
    formId: 'recoverySupplies',
    pageId: 'recoveryTissue',
    queryId: PAGES.RECOVERY_SUPPLIES,
    component: lazy(() => import('src/views/recovery/Supplies')),
  },
  {
    label: 'Recovery - Donor Information',
    formId: 'recoveryDonorInformation',
    pageId: 'recoveryDonorInformation',
    queryId: PAGES.RECOVERY_DONOR_INFORMATION,
    component: lazy(() => import('src/views/recovery/DonorInformation')),
  },
  {
    label: 'Recovery - Recovery Site',
    formId: 'recoveryRecoverySite',
    pageId: 'recoverySite',
    queryId: PAGES.RECOVERY_RECOVERY_SITE,
    component: lazy(() => import('src/views/recovery/RecoverySite')),
  },
  {
    label: 'Recovery - Physical Assessment',
    formId: 'recoveryPhysicalAssessment',
    pageId: 'recoveryPhysicalAssessment',
    queryId: PAGES.RECOVERY_PHYSICAL_ASSESSMENT,
    component: lazy(() => import('src/views/recovery/PhysicalAssessment')),
  },
  {
    label: 'Recovery - Eye Assessment',
    formId: 'recoveryEyeAssessment',
    pageId: 'recoveryEyeAssessment',
    queryId: PAGES.RECOVERY_EYE_ASSESSMENT,
    component: lazy(() => import('src/views/recovery/EyeAssessment')),
  },
  {
    label: 'Recovery - Tissue Recovery',
    formId: 'recoveryTissuesRecovered',
    pageId: 'recoveryTissue',
    queryId: PAGES.RECOVERY_TISSUE_RECOVERY,
    component: lazy(() => import('src/views/recovery/TissueRecoveryView')),
  },
  {
    label: 'Recovery - Shipping Details',
    formId: 'recoveryShippingDetails',
    pageId: 'recoveryShippingDetail',
    queryId: PAGES.RECOVERY_SHIPPING_DETAILS,
    component: lazy(() => import('src/views/recovery/RecoveryShippingDetails')),
  },
  {
    label: 'Recovery - Serologies',
    formId: 'recoverySerologies',
    pageId: 'recoverySerology',
    queryId: PAGES.RECOVERY_SEROLOGIES,
    component: lazy(
      () => import('src/views/recovery/Serologies/SerologiesTransmit'),
    ),
  },
  {
    label: 'Medical Review - Summary',
    formId: 'medicalReviewSummary',
    pageId: 'medicalReviewSummary',
    queryId: PAGES.MEDICAL_REVIEW_SUMMARY,
    component: lazy(() => import('src/views/medicalReview/Summary')),
  },
  {
    label: 'Medical Review - Pathology',
    formId: 'medicalReviewPathology',
    pageId: 'medicalReviewPathology',
    queryId: PAGES.MEDICAL_REVIEW_PATHOLOGY,
    component: lazy(() => import('src/views/medicalReview/PathologyView')),
  },
  {
    label: 'Medical Review - Lab Values',
    formId: 'labValues',
    pageId: 'medicalReviewLabValues',
    queryId: PAGES.MEDICAL_REVIEW_CBC_TEMP_MEDS,
    component: lazy(() => import('src/views/medicalReview/LabValues')),
  },
  {
    label: 'Medical Review - Culture Results',
    formId: 'medReview',
    pageId: 'medicalReviewCultureResult',
    queryId: PAGES.MEDICAL_REVIEW_CULTURE_RESULTS,
    component: lazy(() => import('src/views/medicalReview/CultureResults')),
  },
  {
    label: 'Medical Review - Hemodilution',
    formId: 'medicalReviewHemodilution',
    pageId: 'medicalReviewHemodilution',
    queryId: PAGES.MEDICAL_REVIEW_HEMODILUTION,
    component: lazy(() => import('src/views/medicalReview/Hemodilution')),
  },
  {
    label: 'Medical Review - Serologies',
    formId: 'medicalReviewSerologies',
    pageId: 'medicalReviewSerology',
    queryId: PAGES.MEDICAL_REVIEW_SEROLOGIES,
    component: lazy(
      () => import('src/views/medicalReview/Serologies/SerologiesTransmit'),
    ),
  },
  {
    label: 'Approval - Case Lock',
    formId: 'approvalsCaseLock',
    pageId: 'caseLock',
    queryId: PAGES.APPROVALS_CASE_LOCK,
    component: lazy(() => import('src/views/approvals/CaseLockView')),
  },
];

export const TRANSMIT_NOTES = [
  {
    label: 'Case Notes',
    formId: 'notes',
    pageId: 'notes',
    queryId: PAGES.NOTES,
    component: lazy(
      () => import('src/views/notes/CaseNotesList/CaseNotesTransmit'),
    ),
  },
  {
    label: 'Case Tasks',
    formId: 'tasks',
    pageId: 'tasks',
    queryId: PAGES.TASKS,
    component: lazy(() => import('src/views/tasks/CaseTasks/CaseTaskTransmit')),
  },

  {
    label: 'Transmit - Log',
    pageId: 'transmitLog',
    queryId: PAGES.TRANSMIT_LOG,
    component: lazy(() => import('src/views/transmit/LogView')),
  },
];

export const TRANSMIT_PACKETS = [
  {
    label: 'Referral Packet',
    pageIndexes: REFERRAL_PACKETS,
    attachments: false,
    authForms: false,
    caseNotes: false,
    drai: false,
  },
  {
    label: 'Recovery Packet',
    pageIndexes: RECOVERY_PACKETS,
    attachments: false,
    authForms: false,
    caseNotes: false,
    drai: false,
  },
  {
    label: 'Medical Review Packet',
    pageIndexes: MEDICAL_PACKETS,
    attachments: false,
    authForms: false,
    caseNotes: false,
    drai: false,
  },
  {
    label: 'Attachments Packet',
    pageIndexes: [],
    attachments: true,
    authForms: false,
    caseNotes: false,
    drai: false,
  },
  {
    label: 'Notes Packet',
    pageIndexes: [],
    attachments: false,
    authForms: false,
    caseNotes: true,
    drai: false,
  },
];

export const AUTH_FORMS_AUTHORIZATION_OPTIONS = [
  'californiaRegisteredDonorDisclosure',
];
